import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import FlexBase from '../flex/flex'
import Wrap from '../wrap/wrap'
import RawText from '../rawText/rawText'
import Title from '../title/title'
import Texts from '../texts/texts'
import office from '../../assets/images/office.png'
import colors from '../../theme/colors'

const StyledMetodology = styled.section`
  width: 100%;
`

const Flex = styled(FlexBase)`
  padding: 80px 0 110px;
  border-bottom: 1px solid ${colors.lightGrey};

  @media (max-width: 1090px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 0 55px;

    img {
      display: none;
    }
  }
`

const Metodology = ({ t }) => (
  <StyledMetodology id={t('nav.3.hash')}>
    <Wrap>
      <Flex align="center" justify="space-between">
        <Texts width="545px">
          <Title margin="0 0 20px 0">{t('metodology.title')}</Title>
          {t('metodology.paragraphs').map(p => (
            <RawText key={Math.random()} dangerouslySetInnerHTML={{ __html: p }} />
          ))}
        </Texts>
        <img width="495" src={office} alt={t('metodology.alt')} />
      </Flex>
    </Wrap>
  </StyledMetodology>
)

Metodology.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Metodology)
