export default {
  grey: '#9E9E9E',
  white: '#fff',
  black: '#000',
  darkGrey: '#5D5D5D',
  lightGrey: '#CACACA',
  blue: '#61AAD4',
  mineShaft: '#363636',
  coal: '#2E2E2E',
}
