import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import RawText from "../rawText/rawText";
import Title from "../title/title";
import Texts from "../texts/texts";
import hall from "../../assets/images/hall.png";
import FlexBase from "../flex/flex";
import colors from "../../theme/colors";
import Wrap from "../wrap/wrap";

const StyledOffice = styled.section`
  width: 100%;
`;

const Flex = styled(FlexBase)`
  padding: 80px 0 110px;
  border-bottom: 1px solid ${colors.lightGrey};

  @media (max-width: 1090px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 0 55px;

    img {
      margin-top: 30px;
    }
  }
`;

const Office = ({ t }) => (
  <StyledOffice id={t("nav.0.hash")}>
    <Wrap>
      <Flex align="center" justify="space-between">
        <Texts width="460px">
          <Title margin="0 0 20px 0">{t("office.title")}</Title>
          {t("office.paragraphs").map(p => (
            <RawText
              key={Math.random()}
              dangerouslySetInnerHTML={{ __html: p }}
            />
          ))}
        </Texts>
        <img width="591" src={hall} alt={t("office.alt")} />
      </Flex>
    </Wrap>
  </StyledOffice>
);

Office.propTypes = {
  t: PropTypes.func.isRequired
};

export default withNamespaces()(Office);
