import { observable, action } from 'mobx'

class AppStore {
  @observable headerIsOnTop = true

  @observable memberModalIsVisible = false

  @observable newsletterModalIsVisible = false

  @observable activeMemberId

  @observable menuIsOpen = false

  @action
  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen
  }

  @action
  closeMenu() {
    this.menuIsOpen = false
  }

  @action
  toggleHeader(isOnTop) {
    this.headerIsOnTop = isOnTop
  }

  @action
  setMemberVisibility(visible) {
    this.memberModalIsVisible = visible
  }

  @action
  setNewsletterVisibility(visible) {
    this.newsletterModalIsVisible = visible
  }

  @action
  setMemberId(id) {
    this.activeMemberId = id
  }
}

export default new AppStore()
