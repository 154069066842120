import React from 'react'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import Link from '../link/link'
import colors from '../../theme/colors'

const StyledLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(110%);
    flex-direction: column;
    align-items: flex-start;
    padding: 120px 50px 30px 30px;
    height: 100vh;
    background: ${colors.white};
    transition: 0.4s transform ease;
    box-shadow: 3px 5px 5px 5px rgba(0, 0, 0, 0.3);
    ${props => props.isOpen
      && `
      transform: translateX(0);
    `};
  }
`

const Links = inject('appStore')(
  observer(({ t, appStore }) => (
    <StyledLinks isOpen={appStore.menuIsOpen}>
      {t('nav').map(link => (
        <Link data-scroll key={Math.random()} href={`#${link.hash}`}>
          {link.text}
        </Link>
      ))}
    </StyledLinks>
  )),
)

Links.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Links)
