import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  align-items: ${props => props.align};
  justify-content: ${props => props.justify || 'stretch'};
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'no-wrap')};
  padding: ${props => props.direction};
  width: 100%;
  height: 100%;
`

export default Flex
