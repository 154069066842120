import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'
import Wrap from '../wrap/wrap'
import Title from '../title/title'
import Subtitle from '../subtitle/subtitle'
import List from '../list/list'
import AtuationItem from '../atuationItem/atuationItem'
import colors from '../../theme/colors'

const StyledAtuation = styled.section`
  width: 100%;
`

const PaddingBlock = styled.div`
  padding: 0 0 80px;
  border-bottom: 1px solid ${colors.lightGrey};

  @media (max-width: 1090px) {
    padding: 0 0 40px;
  }
`

const Atuation = ({ t }) => (
  <StyledAtuation id={t('nav.2.hash')}>
    <Wrap>
      <PaddingBlock>
        <Title>{t('atuation.title')}</Title>
        <Subtitle>{t('atuation.subtitle')}</Subtitle>
        <List>
          {t('atuation.items').map(item => (
            <AtuationItem
              key={Math.random()}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          ))}
        </List>
      </PaddingBlock>
    </Wrap>
  </StyledAtuation>
)

Atuation.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Atuation)
