import React from 'react'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import colors from '../../../theme/colors'
import Overlay from '../overlay'
import Title from '../../title/title'
import RawText from '../../rawText/rawText'
import Close from '../close'
import { CloseIcon } from '../../icons/icons'
import Form from '../../form/form'
import Btn from '../../btn/btn'

const Modal = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  max-width: 580px;
  width: calc(100% - 20px);
`

@inject('appStore')
@observer
class NewsletterModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    appStore: PropTypes.object.isRequired,
  }

  close = () => {
    const { appStore } = this.props

    appStore.setNewsletterVisibility(false)
  }

  render() {
    const { t, appStore } = this.props

    return (
      <Overlay visible={appStore.newsletterModalIsVisible}>
        <Modal>
          <Close onClick={this.close}>
            <CloseIcon />
          </Close>
          <Title>{t('newsletter.modal.title')}</Title>
          <RawText align="left" margin="0 0 30px">
            {t('newsletter.modal.description')}
          </RawText>
          <Form>
            {t('newsletter.modal.fields').map(field => React.createElement(field.element, { key: Math.random(), ...field.props }))}
            <Btn component={<button type="submit">{t('send')}</button>} />
          </Form>
        </Modal>
      </Overlay>
    )
  }
}

export default withNamespaces()(NewsletterModal)
