import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'

const StyledMainTitle = styled.h1`
  color: ${colors.white};
  font-size: 20px;

  @media (max-width: 939px) {
    font-size: 17px;
  }

  b,
  strong {
    font-size: 57px;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 939px) {
      font-size: 40px;
    }
  }
`

const MainTitle = ({ children }) => <StyledMainTitle>{children}</StyledMainTitle>

MainTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default MainTitle
