import 'core-js/modules/es6.array.find'
import 'core-js/modules/es7.array.includes'
import '@babel/polyfill'
import 'core-js/fn/array'
import 'core-js/es6/object'
import 'core-js/es7/object'
import 'core-js/es6/set'
import 'core-js/es6/map'
import 'core-js/es6/string'
import 'core-js/es6/array'
import 'core-js/es7/array'
import 'react-app-polyfill/ie11'
import 'url-search-params-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import './services/i18n'
import { createGlobalStyle } from 'styled-components'
import { Provider } from 'mobx-react'
import App from './App'

import Sentry from './components/sentry/sentry'

// Fonts
import RobotoLight from './assets/fonts/Roboto/Roboto-Light.woff'
import RobotoLight2 from './assets/fonts/Roboto/Roboto-Light.woff2'
import RobotoRegular from './assets/fonts/Roboto/Roboto-Regular.woff'
import RobotoRegular2 from './assets/fonts/Roboto/Roboto-Regular.woff2'
import RobotoMedium from './assets/fonts/Roboto/Roboto-Medium.woff'
import RobotoMedium2 from './assets/fonts/Roboto/Roboto-Medium.woff2'
import RobotoBold from './assets/fonts/Roboto/Roboto-Bold.woff'
import RobotoBold2 from './assets/fonts/Roboto/Roboto-Bold.woff2'
import CooperHewittBold from './assets/fonts/CooperHewitt/CooperHewitt-Bold.woff'
import CooperHewittBold2 from './assets/fonts/CooperHewitt/CooperHewitt-Bold.woff2'
import CooperHewittBook from './assets/fonts/CooperHewitt/CooperHewitt-Book.woff'
import CooperHewittBook2 from './assets/fonts/CooperHewitt/CooperHewitt-Book.woff2'
import CooperHewittSemibold from './assets/fonts/CooperHewitt/CooperHewitt-Semibold.woff'
import CooperHewittSemibold2 from './assets/fonts/CooperHewitt/CooperHewitt-Semibold.woff2'

import appStore from './stores/appStore'

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        border: 0;
        padding: 0;
        box-sizing: border-box;
        text-decoration: none;
        outline: none;
        color: inherit;
        -webkit-font-smoothing: antialiased;
    }
    html, body, #root {
      width: 100%;
    }
    @font-face {
        font-family: Roboto;
        font-weight: 300;
        src: url(${RobotoLight}), url(${RobotoLight2});
    }
    @font-face {
        font-family: Roboto;
        font-weight: 400;
        src: url(${RobotoRegular}), url(${RobotoRegular2});
    }
    @font-face {
        font-family: Roboto;
        font-weight: 600;
        src: url(${RobotoMedium}), url(${RobotoMedium2});
    }
    @font-face {
        font-family: Roboto;
        font-weight: 700;
        src: url(${RobotoBold}), url(${RobotoBold2});
    }
    @font-face {
        font-family: CooperHewitt;
        font-weight: 700;
        src: url(${CooperHewittBold}), url(${CooperHewittBold2});
    }
    @font-face {
        font-family: CooperHewitt;
        font-weight: 350;
        src: url(${CooperHewittBook}), url(${CooperHewittBook2});
    }
    @font-face {
        font-family: CooperHewitt;
        font-weight: 600;
        src: url(${CooperHewittSemibold}), url(${CooperHewittSemibold2});
    }
    body {
        font-family: Roboto;
        font-weight: 400;
    }
    textarea {
      font-family: inherit;
    }
    img {
      max-width: 100%;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: currentColor;
      font-weight: 400;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: currentColor;
      font-weight: 400;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: currentColor;
      font-weight: 400;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: currentColor;
      font-weight: 400;
    }
`

ReactDOM.render(
  <Sentry>
    <Provider appStore={appStore}>
      <React.Fragment>
        <GlobalStyle />
        <App />
      </React.Fragment>
    </Provider>
  </Sentry>,
  document.getElementById('root'),
)
