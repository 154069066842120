import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import RawText from '../rawText/rawText'
import BlueAction from '../blueAction/blueAction'
import Title from '../title/title'

const Item = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.34);

  img {
    max-width: 100%;
    height: 140px;
    object-fit: cover;
  }
`

const ItemInfo = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  a,
  button {
    margin-top: auto;
  }
`

const PressItem = ({
  img, description, title, alt, href, t,
}) => (
  <Item>
    <img src={img} alt={alt} />
    <ItemInfo>
      <Title size={25} margin="0 0 10px">
        {title}
      </Title>
      <RawText margin="0 0 10px" align="left">
        {description}
      </RawText>
      <hr />
      <BlueAction element="a" href={href} text={t('see_more')} />
    </ItemInfo>
  </Item>
)

PressItem.propTypes = {
  img: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(PressItem)
