import React from 'react'
import styled from 'styled-components'
import { Facebook, Instagram, Linkedin } from '../icons/icons'

const StyledSocialMedia = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  a + a {
    margin-left: 10px;
  }
`

const SocialMedia = () => (
  <StyledSocialMedia>
    <a
      href="https://www.linkedin.com/company/d.b.-tesser-sociedade-de-advogados/?viewAsMember=true"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Linkedin />
    </a>
    <a href="https://www.facebook.com/dbtesser/" rel="noopener noreferrer" target="_blank">
      <Facebook />
    </a>
    <a href="https://www.instagram.com/db.tesser/" rel="noopener noreferrer" target="_blank">
      <Instagram />
    </a>
  </StyledSocialMedia>
)

export default SocialMedia
