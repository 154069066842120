import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../../theme/colors";

const Paragraph = styled.p`
  font-size: 15px;
  color: ${props => props.color};
  text-align: ${props => props.align};
  margin: ${props => props.margin};
  width: 100%;

  ${props =>
    props.intern &&
    `
    b {
    margin-top: 1em;
    display: block;
  }
  
  `}
`;

const RawText = ({ children, ...rest }) => {
  if (typeof children === "string") {
    return (
      <Paragraph {...rest} dangerouslySetInnerHTML={{ __html: children }} />
    );
  }
  return <Paragraph {...rest}>{children}</Paragraph>;
};

RawText.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string
};

RawText.defaultProps = {
  color: colors.darkGrey,
  align: "justify",
  margin: "0"
};

export default RawText;
