import React from 'react'
import styled from 'styled-components'
import colors from '../../theme/colors'
import { Symbol } from '../icons/icons'

const StyledPlaceholder = styled.div`
  width: 100%;
  height: 195px;
  background: ${colors.lightGrey};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Placeholder = props => (
  <StyledPlaceholder {...props}>
    <Symbol />
  </StyledPlaceholder>
)

export default Placeholder
