import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Btn = styled.button`
  order: ${props => (props.selected ? 1 : 2)};
  pointer-events: ${props => (props.selected ? 'none' : 'all')};
  display: flex;
  background: none;
  width: 100%;
  cursor: pointer;
  padding: 5px 0;
`

const LangButton = ({ lng, changeLang, selected }) => (
  <Btn type="button" selected={selected} onClick={() => changeLang(lng)}>
    <img width="18" src={require(`../../assets/flags/${lng.split('-')[0]}.png`)} alt="" />
  </Btn>
)

LangButton.defaultProps = {
  selected: false,
}

LangButton.propTypes = {
  selected: PropTypes.bool,
  lng: PropTypes.string.isRequired,
  changeLang: PropTypes.func.isRequired,
}

export default LangButton
