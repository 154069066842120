import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { withNamespaces } from 'react-i18next'
import PropTypes from 'prop-types'
import Arrow from '../arrow/arrow'
import LangButton from '../langButton/langButton'
import i18n from '../../services/i18n'
import colors from '../../theme/colors'

const Select = styled.div`
  width: ${props => (props.headerIsOnTop ? '52px' : '62px')};
  height: ${props => (props.headerIsOnTop ? '22px' : '35px')};
  cursor: pointer;
  margin-left: 37px;

  @media (max-width: 900px) {
    margin-left: 18px;
  }
`
// height: 22 -> each flag height
const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  border: ${props => (props.headerIsOnTop ? 0 : `2px solid ${colors.lightGrey}`)};
  padding: ${props => (props.headerIsOnTop ? 0 : '5px 10px')};
  background: ${props => (props.headerIsOnTop ? 'transparent' : colors.white)};
  transition: 0.4s height ease;
  width: 100%;
  position: relative;
  height: ${(props) => {
    if (props.opened) {
      const height = props.headerIsOnTop ? 22 : 25
      return props.children ? `${(props.children.length - 1) * height}px` : '100%'
    }

    return '100%'
  }};

  svg {
    position: absolute;
    top: ${props => (props.headerIsOnTop ? '7px' : '12px')};
    right: 10px;
    transition: 0.4s transform ease;
    transform: ${props => (props.opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`
@inject('appStore')
@observer
class LangSelect extends React.Component {
  static propTypes = {
    appStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    opened: false,
    selectedLang: i18n.language,
  }

  componentWillMount() {
    const { t } = this.props

    const meta = document.querySelector('meta[name="description"]')
    meta.content = t('meta_description')
  }

  changeLang = (lang) => {
    i18n.changeLanguage(lang)

    this.setState({ opened: false, selectedLang: lang })

    const { t } = this.props

    // Changing meta description
    const meta = document.querySelector('meta[name="description"]')
    meta.content = t('meta_description')

    // Changing html lang
    const html = document.querySelector('html')
    html.lang = lang

    // Changing query param
    const params = new URLSearchParams(window.location.search)
    params.set('lang', lang)
    window.history.pushState('', '', `?${params.toString()}`)
  }

  openSelect = () => {
    const { opened } = this.state

    this.setState({ opened: !opened })
  }

  render() {
    const { opened, selectedLang } = this.state
    const { appStore } = this.props

    return (
      <Select onClick={this.openSelect} headerIsOnTop={appStore.headerIsOnTop}>
        <Options opened={opened} headerIsOnTop={appStore.headerIsOnTop}>
          <LangButton
            selected={selectedLang.startsWith('pt')}
            changeLang={this.changeLang}
            lng="pt-BR"
          />
          <LangButton
            selected={selectedLang.startsWith('en')}
            changeLang={this.changeLang}
            lng="en-US"
          />
          <LangButton
            selected={selectedLang.startsWith('es')}
            changeLang={this.changeLang}
            lng="es"
          />
          <LangButton
            selected={selectedLang.startsWith('zh')}
            changeLang={this.changeLang}
            lng="zh"
          />
          <Arrow
            color={!appStore.headerIsOnTop || appStore.menuIsOpen ? colors.black : colors.white}
          />
        </Options>
      </Select>
    )
  }
}

export default withNamespaces()(LangSelect)
