import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'

const StyledTitle = styled.h2`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  font-weight: 700;
  font-family: ${props => (props.fontType === 2 ? 'CooperHewitt' : 'Roboto')};
  margin: ${props => props.margin};
`

const Title = ({ children, ...props }) => <StyledTitle {...props}>{children}</StyledTitle>

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  color: PropTypes.string,
  fontType: PropTypes.number,
  margin: PropTypes.string,
  size: PropTypes.number,
}

Title.defaultProps = {
  color: colors.darkGrey,
  fontType: 2,
  margin: '0',
  size: 35,
}

export default Title
