import React from 'react'
import styled, { keyframes } from 'styled-components'
import { withNamespaces } from 'react-i18next'
import PropTypes from 'prop-types'
import background from '../../assets/images/splash_home_2023.png'
import backgroundMobile from '../../assets/images/splash_home_2023_vert.png'
import Wrap from '../wrap/wrap'
import MainTitle from '../mainTitle/mainTitle'
import { AngleDown } from '../icons/icons'
import colors from '../../theme/colors'
import Btn from '../btn/btn'

const bounce = keyframes`
  from {
    transform: translate(-50%, 0)
  }
  to {
    transform: translate(-50%, 20px)
  }
`

const StyledBanner = styled.section`
  background: url(${background}) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;

  @media (max-width: 815px) {
    min-height: 100vh;
    padding-top: 140px; /* Header height more some margin */
    padding-bottom: 80px;
    height: auto;
  }

  @media (max-width: 570px) {
    background: url(${backgroundMobile}) no-repeat center;
    background-size: cover;
  }

  svg {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    animation: ${bounce} 1s ease-in-out alternate 0s infinite;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  form {
    padding-left: 70px;
    border-left: 1px solid ${colors.white};
  }

  @media (max-width: 815px) {
    flex-direction: column-reverse;

    form {
      border-left: 0;
      border-bottom: 1px solid ${colors.white};
      padding-left: 0;
      padding-bottom: 35px;
      margin-bottom: 35px;
    }
  }
`

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;

  a {
    margin-top: 30px;
    align-self: center;
  }
`

const Banner = ({ t }) => (
  <StyledBanner id="home">
    <Wrap>
      <Flex>
        <Texts>
          <MainTitle>
            {t('company_name')}
            <br />
            <strong dangerouslySetInnerHTML={{ __html: t('banner_title.description') }} />
          </MainTitle>

          <Btn
            color={colors.white}
            isBigger
            component={(
              <a data-scroll href={`#${t('nav.0.hash')}`}>
                {t('see_more')}
              </a>
            )}
          />
        </Texts>

        <a data-scroll href={`#${t('nav.0.hash')}`}>
          <AngleDown color={colors.white} />
        </a>
      </Flex>
    </Wrap>
  </StyledBanner>
)

Banner.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Banner)
