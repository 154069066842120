import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../theme/colors'

const BlueAction = styled(({ element, text, ...props }) => React.createElement(element, props, text))`
  color: ${colors.blue};
  font-size: 14px;
  cursor: pointer;
  background: none;
`

BlueAction.propTypes = {
  text: PropTypes.string.isRequired,
}

export default BlueAction
