import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'

const StyledAngleDown = styled.svg`
  width: 30px;
  height: 64px;
  color: ${props => props.color};
`

const AtuationIcon = styled.svg`
  width: 32px;
  height: 30px;
  fill: ${colors.lightGrey};
  color: ${colors.lightGrey};
`

const FooterIcon = styled.svg`
  width: 21px;
  height: 21px;
  fill: ${colors.white};
  color: ${colors.white};
`

const StyledSymbol = styled.svg`
  width: 85px;
  height: 55px;
  fill: ${colors.grey};
  color: ${colors.grey};
`

const StyledEnvelope = styled.svg`
  width: 50px;
  height: 53px;
  fill: ${colors.darkGrey};
  color: ${colors.darkGrey};
`

export const AngleDown = ({ color }) => (
  <StyledAngleDown
    color={color}
    aria-hidden="true"
    data-prefix="fal"
    data-icon="angle-down"
    className="svg-inline--fa fa-angle-down fa-w-8"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 512"
  >
    <path
      fill="currentColor"
      d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
    />
  </StyledAngleDown>
)

AngleDown.propTypes = {
  color: PropTypes.string.isRequired,
}

export const Star = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="star"
    className="svg-inline--fa fa-star fa-w-18"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
    />
  </AtuationIcon>
)

export const Briefcase = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="briefcase-medical"
    className="svg-inline--fa fa-briefcase-medical fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M344 288h-56v-56c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v56h-56c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h56v56c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-56h56c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm120-160H352V80c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v48H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V176c0-26.5-21.5-48-48-48zM208 80h96v48h-96V80zm256 378c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V182c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v276z"
    />
  </AtuationIcon>
)

export const Cash = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="usd-circle"
    className="svg-inline--fa fa-usd-circle fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M291 244l-72-21.9c-9-2.8-15.2-12.1-15.2-22.7 0-12.9 9.2-23.4 20.5-23.4h45c7 0 13.8 1.9 19.9 5.4 6.4 3.7 14.3 3.4 19.7-1.6l12-11.3c7.6-7.2 6.3-19.4-2.3-25.2-13.8-9.3-29.9-14.5-46.4-15.1V112c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v16c-37.6.1-68.2 32.1-68.2 71.4 0 31.5 20.2 59.7 49.2 68.6l72 21.9c9 2.8 15.2 12.1 15.2 22.7 0 12.9-9.2 23.4-20.5 23.4h-45c-7 0-13.8-1.9-19.9-5.4-6.4-3.7-14.3-3.4-19.7 1.6l-12 11.3c-7.6 7.2-6.3 19.4 2.3 25.2 13.8 9.3 29.9 14.5 46.4 15.1V400c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16c37.6-.1 68.2-32.1 68.2-71.4 0-31.5-20.2-59.7-49.2-68.6zM248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200z"
    />
  </AtuationIcon>
)

export const Checks = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="check-double"
    className="svg-inline--fa fa-check-double fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M444.09 166.99l-27.39-28.37c-2.6-1.96-5.53-2.93-8.8-2.93-3.27 0-5.87.98-7.82 2.93L142.81 396.86l-94.88-94.88c-1.96-2.61-4.55-3.91-7.82-3.91-3.27 0-6.21 1.3-8.8 3.91l-27.4 27.38c-2.6 2.61-3.91 5.55-3.91 8.8s1.31 5.87 3.91 7.82l130.1 131.07c2.6 1.96 5.53 2.94 8.8 2.94 3.27 0 5.87-.98 7.82-2.94L444.08 183.6c2.6-2.61 3.91-5.55 3.91-8.8.01-3.24-1.3-5.86-3.9-7.81zM131.88 285.04c2.62 1.97 5.58 2.96 8.88 2.96s5.92-.99 7.89-2.96L353.34 80.35c2.62-2.64 3.95-5.6 3.95-8.88 0-3.28-1.33-5.92-3.95-7.89l-27.63-28.62c-2.62-1.97-5.58-2.96-8.88-2.96s-5.92.99-7.89 2.96L140.76 204.12l-60.41-60.41c-1.97-2.64-4.59-3.95-7.89-3.95s-6.26 1.31-8.88 3.95l-27.63 27.63c-2.62 2.64-3.95 5.6-3.95 8.88 0 3.29 1.33 5.92 3.95 7.89l95.93 96.93z"
    />
  </AtuationIcon>
)

export const Envelope = () => (
  <StyledEnvelope
    aria-hidden="true"
    data-prefix="fal"
    data-icon="envelope"
    className="svg-inline--fa fa-envelope fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"
    />
  </StyledEnvelope>
)

export const Facebook = () => (
  <FooterIcon
    id="adf4e53c-363d-446d-b414-9ee64c1d0015"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.42986 21.42986"
  >
    <title>facebook</title>
    <path
      d="M20.25,1.179A3.87007,3.87007,0,0,0,17.411,0H4.018A3.87,3.87,0,0,0,1.179,1.179,3.86994,3.86994,0,0,0,0,4.018V17.411A3.87,3.87,0,0,0,1.179,20.25a3.86974,3.86974,0,0,0,2.839,1.178H11.44v-8.3H8.65V9.891h2.789V7.505a4.21789,4.21789,0,0,1,1.137-3.153A4.144,4.144,0,0,1,15.61,3.236a18.7005,18.7005,0,0,1,2.483.126V6.25l-1.7.014a1.6268,1.6268,0,0,0-1.277.391,1.78754,1.78754,0,0,0-.328,1.172V9.892h3.195L17.564,13.129H14.788v8.3h2.623a4.02545,4.02545,0,0,0,4.018-4.018V4.018A3.87,3.87,0,0,0,20.25,1.179Z"
      transform="translate(0.00042 0.00044)"
    />
  </FooterIcon>
)

export const File = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="file"
    className="svg-inline--fa fa-file fa-w-12"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"
    />
  </AtuationIcon>
)

export const Gavel = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="gavel"
    className="svg-inline--fa fa-gavel fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M497.965 176.618l-23.185-23.185c-13.611-13.61-33.433-17.321-50.434-11.133l-54.624-54.624c6.189-16.998 2.479-36.821-11.133-50.433l-23.185-23.174c-18.757-18.757-49.122-18.76-67.882 0L163.914 117.667c-18.715 18.715-18.715 49.167 0 67.883l23.184 23.184c13.613 13.613 33.433 17.326 50.434 11.133l10.342 10.342-56.543 56.52c-22.021-22.02-51.866-19.249-69.498-1.616L14.069 392.908c-18.757 18.757-18.76 49.122 0 67.882l37.163 37.174c18.714 18.714 49.165 18.715 67.882 0l107.773-107.796c17.412-17.41 20.652-47.231-1.616-69.499l56.543-56.519 10.341 10.341c-6.189 16.998-2.479 36.821 11.134 50.434l25.417 25.417c17.484 17.484 45.932 17.485 63.417 0L497.965 244.5c18.713-18.715 18.713-49.167 0-67.882zM85.195 464.043l-.021-.021L48 426.849l107.773-107.795 37.173 37.173L85.195 464.043zm275.219-149.875l-23.184-23.184 14.793-14.793L235.832 160l-14.792 14.792-23.184-23.184L301.465 48l23.184 23.184L307.832 88l116.191 116.191 16.816-16.816 23.184 23.184-103.609 103.609z"
    />
  </AtuationIcon>
)

export const HandPaper = () => (
  <AtuationIcon
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="hand-paper"
    className="svg-inline--fa fa-hand-paper fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M372.57 112.641v-10.825c0-43.612-40.52-76.691-83.039-65.546-25.629-49.5-94.09-47.45-117.982.747C130.269 26.456 89.144 57.945 89.144 102v126.13c-19.953-7.427-43.308-5.068-62.083 8.871-29.355 21.796-35.794 63.333-14.55 93.153L132.48 498.569a32 32 0 0 0 26.062 13.432h222.897c14.904 0 27.835-10.289 31.182-24.813l30.184-130.958A203.637 203.637 0 0 0 448 310.564V179c0-40.62-35.523-71.992-75.43-66.359zm27.427 197.922c0 11.731-1.334 23.469-3.965 34.886L368.707 464h-201.92L51.591 302.303c-14.439-20.27 15.023-42.776 29.394-22.605l27.128 38.079c8.995 12.626 29.031 6.287 29.031-9.283V102c0-25.645 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V67c0-25.663 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V101.125c0-25.672 36.57-24.81 36.57.691V256c0 8.837 7.163 16 16 16h6.857c8.837 0 16-7.163 16-16v-76.309c0-26.242 36.57-25.64 36.57-.691v131.563z"
    />
  </AtuationIcon>
)

export const Instagram = () => (
  <FooterIcon
    id="a048eb18-fb3c-40c4-a1b0-618284f488f6"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.42999 21.42999"
  >
    <title>instagram</title>
    <path
      d="M17.72,5a1.28023,1.28023,0,0,1-1.29,1.28A1.28028,1.28028,0,0,1,15.15,5a1.287,1.287,0,0,1,2.19-.91A1.25446,1.25446,0,0,1,17.72,5Z"
      transform="translate(0 0.00002)"
    />
    <path
      d="M14.61,6.83V6.82a5.31151,5.31151,0,0,0-3.9-1.59,5.27555,5.27555,0,0,0-3.89,1.6,5.31459,5.31459,0,0,0-1.6,3.89,5.31445,5.31445,0,0,0,1.6,3.89,5.27566,5.27566,0,0,0,3.89,1.6,5.282,5.282,0,0,0,3.9-1.6,5.35362,5.35362,0,0,0,1.6-3.89A5.35376,5.35376,0,0,0,14.61,6.83Zm-1.37,6.41A3.44321,3.44321,0,0,1,10.71,14.29,3.421,3.421,0,0,1,8.19,13.24a3.561,3.561,0,0,1,0-5.05,3.561,3.561,0,0,1,5.05,0,3.43418,3.43418,0,0,1,1.04,2.53A3.41169,3.41169,0,0,1,13.24,13.24Z"
      transform="translate(0 0.00002)"
    />
    <path
      d="M21.36,6.3V6.29A6.59181,6.59181,0,0,0,19.63,1.8,6.563,6.563,0,0,0,15.14.07C14.32.02,12.84,0,10.71,0S7.11.02,6.29.07A6.499,6.499,0,0,0,1.8,1.8,6.49875,6.49875,0,0,0,.07,6.29C.02,7.11,0,8.58,0,10.71s.02,3.61.07,4.43A6.4986,6.4986,0,0,0,1.8,19.63a6.49874,6.49874,0,0,0,4.49,1.73c.82.05,2.29.07,4.42.07s3.61-.02,4.43-.07a6.51631,6.51631,0,0,0,4.49-1.73,6.59167,6.59167,0,0,0,1.73-4.49c.04-.82.07-2.29.07-4.42S21.4,7.12,21.36,6.3Zm-1.85,6.95c0,.34-.01.78-.04,1.35A9.75541,9.75541,0,0,1,19.33,16.03a4.90377,4.90377,0,0,1-.26,1,3.59513,3.59513,0,0,1-2.03,2.04,5.88362,5.88362,0,0,1-1,.26,11.69372,11.69372,0,0,1-1.44.14c-.56.01995-1.00995.04-1.35.04H11.78l-1.07-.01-1.06.01q-.975.015-1.47,0c-.34,0-.79-.01-1.35-.04a9.75277,9.75277,0,0,1-1.43-.14,4.73814,4.73814,0,0,1-1-.26,3.38292,3.38292,0,0,1-1.23-.81,3.47577,3.47577,0,0,1-.81-1.22,5.08126,5.08126,0,0,1-.26-1,11.69585,11.69585,0,0,1-.14-1.44c-.02-.56-.04-1.01-.04-1.35q-.015-.49493,0-1.47V10.72c0-.06,0-.42-.01-1.06V8.18c.01-.33.02-.78.05005-1.34A10.02522,10.02522,0,0,1,2.1,5.4a5.632,5.632,0,0,1,.25-1A3.65194,3.65194,0,0,1,4.39,2.36,6.999,6.999,0,0,1,5.39,2.1a11.67152,11.67152,0,0,1,1.44-.13c.56-.03,1.01-.04,1.34-.05H9.65l1.06.01,1.07-.01c.65-.01,1.14-.01,1.47-.01.33.01.78.02,1.35.04a13.57633,13.57633,0,0,1,1.43.14,5.65311,5.65311,0,0,1,1,.26,3.65179,3.65179,0,0,1,2.04,2.04,5.65176,5.65176,0,0,1,.26,1A11.35205,11.35205,0,0,1,19.47,6.82c.02.57.04,1.02.04,1.35v3.61Q19.525,12.755,19.51,13.25Z"
      transform="translate(0 0.00002)"
    />
  </FooterIcon>
)

export const Linkedin = () => (
  <FooterIcon
    id="b6922462-104a-4ace-9a4e-9ff3485e32ee"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.43083 21.43083"
  >
    <title>linkedin</title>
    <path
      d="M20.25,1.18A3.84327,3.84327,0,0,0,17.41,0H4.02A3.84327,3.84327,0,0,0,1.18,1.18,3.84308,3.84308,0,0,0,0,4.02V17.41a3.84284,3.84284,0,0,0,1.18,2.84,3.88123,3.88123,0,0,0,2.84,1.18H17.41a4.02879,4.02879,0,0,0,4.02-4.02V3.98A3.88664,3.88664,0,0,0,20.25,1.18ZM6.53,17.94H3.31V8.26H6.53ZM6.24,6.46a1.8127,1.8127,0,0,1-1.33.48H4.9a1.79455,1.79455,0,0,1-1.29-.47,1.60114,1.60114,0,0,1-.5-1.2,1.5744,1.5744,0,0,1,.51-1.2A1.86637,1.86637,0,0,1,4.94,3.6a1.81821,1.81821,0,0,1,1.3.47,1.69577,1.69577,0,0,1,.5,1.2A1.57926,1.57926,0,0,1,6.24,6.46ZM18.12,17.94H14.9V12.76q0-2.19-1.62-2.19a1.55189,1.55189,0,0,0-1.03.35,2.16,2.16,0,0,0-.63.83,2.53172,2.53172,0,0,0-.1.78v5.41H8.3q.045-8.76,0-9.68h3.23V9.63A3.2119,3.2119,0,0,1,14.41,8.04a3.49733,3.49733,0,0,1,2.69,1.1,4.59476,4.59476,0,0,1,1.02,3.25Z"
      transform="translate(0.00067 0.00068)"
    />
    <path
      d="M11.53,9.63v.04H11.5A.13818.13818,0,0,1,11.53,9.63Z"
      transform="translate(0.00067 0.00068)"
    />
  </FooterIcon>
)

export const Whatsapp = () => (
  <FooterIcon
    id="b3e66467-bb2c-4cc5-bd53-ea39695cc458"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 448"
  >
    <title>whatsapp</title>
    <path d="M332.4,333.5c-4.6,13-26.7,24.8-37.4,26.4-17.6,2.6-31.4,1.3-66.6-13.9-55.7-24.1-92-80.1-94.8-83.8-2.7-3.7-22.6-30.1-22.6-57.4s14.3-40.7,19.4-46.3a20.54858,20.54858,0,0,1,14.8-6.9c3.7,0,7.4,0,10.6.2,3.4.2,8-1.3,12.5,9.5,4.6,11.1,15.7,38.4,17.1,41.2,1.4,2.8,2.3,6,.5,9.7-10.6,21.2-22,20.5-16.3,30.3,21.5,36.9,42.9,49.7,75.5,66,5.5,2.8,8.8,2.3,12-1.4,3.3-3.8,13.9-16.2,17.6-21.8,3.7-5.6,7.4-4.7,12.5-2.8,5.1,1.8,32.4,15.2,37.9,18,5.5,2.7,9.2,4.1,10.5,6.6C337,309.4,337,320.5,332.4,333.5Z" transform="translate(0 -32)" />
    <path d="M380.9,97.1A220.32915,220.32915,0,0,0,223.9,32c-122.4,0-222,99.6-222,222A222.23843,222.23843,0,0,0,31.5,365L0,480l117.7-30.9a221.32385,221.32385,0,0,0,106.1,27h.1c122.3,0,224.1-99.6,224.1-222C448,194.8,422.8,139.1,380.9,97.1Zm-157,341.6a184.19132,184.19132,0,0,1-94-25.7l-6.7-4L53.4,427.3,72,359.2l-4.4-7A183.90839,183.90839,0,0,1,39.4,254C39.4,152.3,122.2,69.5,224,69.5a182.88011,182.88011,0,0,1,130.4,54.1c34.8,34.9,56.2,81.2,56.1,130.5C410.5,355.9,325.6,438.7,223.9,438.7Z" transform="translate(0 -32)" />
  </FooterIcon>
)

export const Search = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="search"
    className="svg-inline--fa fa-search fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
    />
  </AtuationIcon>
)

export const Suitcase = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="suitcase"
    className="svg-inline--fa fa-suitcase fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M464 128h-80V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v48H48c-26.5 0-48 21.5-48 48v256c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V176c0-26.5-21.5-48-48-48zM176 80h160v48H176V80zM48 432V176h80v256H48zm128 0V176h160v256H176zm288 0h-80V176h80v256z"
    />
  </AtuationIcon>
)

export const Symbol = ({ ...props }) => (
  <StyledSymbol
    id="b2c9d7ca-d127-4347-b4f4-76fcf0a0ee19"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 86.59003 55.06"
    {...props}
  >
    <title>symbol</title>
    <path
      d="M0,0V55.06H86.59V0ZM51.03,51.04H4.04v-47H51.03Zm31.54,0H55.06V23.52H82.6Zm.03-31.55H55.06V4.03H82.6Z"
      transform="translate(0 0.00002)"
    />
  </StyledSymbol>
)

export const Unlock = () => (
  <AtuationIcon
    aria-hidden="true"
    data-prefix="far"
    data-icon="unlock-alt"
    className="svg-inline--fa fa-unlock-alt fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M400 240H128v-94.8c0-52.8 42.1-96.7 95-97.2 53.4-.6 97 42.7 97 96v24c0 13.3 10.7 24 24 24s24-10.7 24-24v-22.6C368 65.8 304 .2 224.3 0 144.8-.2 80 64.5 80 144v96H48c-26.5 0-48 21.5-48 48v176c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V288c0-26.5-21.5-48-48-48zm0 224H48V288h352v176zm-176-32c-15.5 0-28-12.5-28-28v-56c0-15.5 12.5-28 28-28s28 12.5 28 28v56c0 15.5-12.5 28-28 28z"
    />
  </AtuationIcon>
)

const StyledClose = styled.svg`
  width: 18px;
  height: 18px;
  color: ${colors.darkGrey};
  fill: ${colors.darkGrey};
`

export const CloseIcon = () => (
  <StyledClose
    id="a897a433-0135-4954-a185-b298a99a4c84"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 319.995 320.00499"
  >
    <title>times-regular</title>
    <path
      d="M315.325,363.71,207.595,256l107.72-107.72a15.976,15.976,0,0,0,0-22.58l-25.03-25.03a15.97612,15.97612,0,0,0-22.58,0L159.995,208.4,52.275,100.68a15.97612,15.97612,0,0,0-22.58,0L4.675,125.7a15.97605,15.97605,0,0,0,0,22.58L112.395,256,4.675,363.72a15.97613,15.97613,0,0,0,0,22.58l25.03,25.03a15.97592,15.97592,0,0,0,22.58,0L159.995,303.6l107.72,107.72a15.976,15.976,0,0,0,22.57995,0l25.03-25.03A15.97605,15.97605,0,0,0,315.325,363.71Z"
      transform="translate(-0.0025 -95.99751)"
    />
  </StyledClose>
)
