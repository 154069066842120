import React from 'react'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'
import RawText from '../rawText/rawText'
import BlueAction from '../blueAction/blueAction'
import Placeholder from './imgPlaceholder'

const Item = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  margin: 15px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.34);

  img {
    max-width: 100%;
    max-height: 195px;
  }

  hr {
    width: 100%;
    margin: auto 0 8px;
    height: 1px;
    background: ${colors.lightGrey};
  }
`

const MemberInfo = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

@inject('appStore')
@observer
class Member extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    img: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    alt: PropTypes.string.isRequired,
    appStore: PropTypes.object.isRequired,
  }

  static defaultProps = {
    img: null,
  }

  handleClick = (id) => {
    const { appStore } = this.props

    appStore.setMemberId(id)
    appStore.setMemberVisibility(true)
  }

  render() {
    const {
      id, img, name, title, alt, t,
    } = this.props

    return (
      <Item>
        {img !== null ? (
          <img src={require(`../../assets/members/${img}`)} alt={`${alt} ${name}`} />
        ) : (
          <Placeholder />
        )}
        <MemberInfo>
          <RawText align="center">
            <b>{name}</b>
          </RawText>
          <RawText margin="0 0 20px" align="center">
            {title}
          </RawText>
          <hr />
          <BlueAction
            onClick={() => this.handleClick(id)}
            element="button"
            type="button"
            text={t('see_more')}
          />
        </MemberInfo>
      </Item>
    )
  }
}

export default withNamespaces()(Member)
