import React from 'react'
import Header from './components/header/header'
import Banner from './components/banner/banner'
import Office from './components/office/office'
import History from './components/history/history'
import Values from './components/values/values'
import Atuation from './components/atuation/atuation'
import Metodology from './components/metodology/metodology'
import Team from './components/team/team'
import Press from './components/press/press'
import WorkWithUs from './components/workWithUs/workWithUs'
import Footer from './components/footer/footer'
import ModalMember from './components/modal/member/modalMember'
import ModalNewsletter from './components/modal/newsletter/modalNewsletter'

const App = () => (
  <React.Fragment>
    <ModalMember />
    <ModalNewsletter />
    <Header />
    <Banner />
    <Office />
    <History />
    <Values />
    <Atuation />
    <Metodology />
    <Team />
    <Press />
    <WorkWithUs />
    <Footer />
  </React.Fragment>
)

export default App
