import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTexts = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  margin: ${props => props.margin};

  p + p {
    margin-top: ${props => (props.noMargin ? '0' : '20px')};
  }

  & + & {
    margin-top: 30px;
  }

  ${props => props.width.match(/px/)
    && `
    @media (max-width: ${`${parseInt(props.width, 10) + 20}px`}) {
      width: 100%;
    }
  `}
`

const Texts = ({ children, ...props }) => <StyledTexts {...props}>{children}</StyledTexts>

Texts.defaultProps = {
  width: '100%',
  noMargin: false,
  margin: '0',
}

Texts.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  width: PropTypes.string,
  noMargin: PropTypes.bool,
  margin: PropTypes.string,
}

export default Texts
